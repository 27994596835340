<template>
  <div class="cont">
    <div class="years">
      <p class="years-text1">
        {{$t('about.years.text1')}}
      </p>
      <p class="years-text2">
        {{$t('about.years.text2')}}
      </p>
    </div>
    <div class="pandaMCN" style="margin: 0">
    <div class="pandaMCN-title">
      {{$t('about.profile.text1')}}
    </div>
      <div class="pandaMCN-text3">
        {{$t('about.profile.text2')}}
      </div>
      <div class="pandaMCN-cont">
        <div class="pandaMCN-text">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="1300" v-if="showNum" />
            {{$t('about.profile.text3')}}
          </div>
          <div class="pandaMCN-text2" style="background: #222222">
            {{$t('about.profile.text4')}}
          </div>
        </div>
        <div class="pandaMCN-text" style="margin: 230px 0">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="2600" v-if="showNum" />
            +
          </div>
          <div class="pandaMCN-text2" style="background: #0E653B">
            {{$t('about.profile.text5')}}
          </div>
        </div>
        <div class="pandaMCN-text">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="1" v-if="showNum" />
            {{$t('about.profile.text6')}}+
          </div>
          <div class="pandaMCN-text2" style="background: #F7941F">
            {{$t('about.profile.text7')}}
          </div>
        </div>
      </div>

    </div>
    <div class="memorabilia">
      <div class="memorabilia-title">
        {{$t('about.memorabilia.text1')}}
      </div>
      <div class="memorabilia-text">
        {{$t('about.memorabilia.listText'+currentPage)}}
      </div>
      <div class="memorabilia-num">
        <div class="memorabilia-num-btn" v-show="listData[currentPage-1]">
          <img src="../assets/about/btn.png" style="transform:rotate(180deg)" @click="slidePrev">
          <span style="margin-left: 20px">
            {{listData[currentPage-1]}}
          </span>
        </div>
        <div class="memorabilia-num-cont">
          <slider ref="slider" :options="options" @slide="slide">
            <slideritem  v-for="(item,index) in listData" :key="index" >
              <div class="slider-text">
                {{item}}
              </div>
            </slideritem>
          </slider>
        </div>
        <div class="memorabilia-num-btn" v-show="listData[currentPage+1]">
            <span style="margin-right: 20px">
            {{listData[currentPage+1]}}
          </span>
          <img src="../assets/about/btn.png" @click="slideNext">
        </div>
      </div>
      <div class="memorabilia-index">
          <div v-for="(item,index) in listData" :key="index" class="memorabilia-index-item" :class="[currentPage==index?'memorabilia-bg-aictive':'']">
            <div :class="ei>5&&ei<12?'bg-aictive':'bg-aictive1'"  class="memorabilia-bg" v-for="(el,ei) in 28"></div>
            <div class="memorabilia-index-img" >
              <img src="../assets/about/index.png" alt="">
            </div>
          </div>

      </div>
    </div>
    <div class="honors">
      <div class="honors-left">
        <img src="../assets/about/honors.png" >
        <div class="honors-text1">
          {{$t('about.honors.text1')}}
        </div>
      </div>
      <div class="honors-right">

        <div class="honors-right-item" v-for="(item,index) in 12">
          <div class="honors-right-item-box">
            <div class="honors-text2">
              {{$t('about.honors.text'+(2+index*5))}}
            </div>
            <div class="honors-right-item-img">
              <img src="../assets/about/honors1.png">
              <div class="honors-right-item-add">
                +
              </div>
            </div>
            <div class="honors-text-box">
              <span class="honors-text3">
                {{$t('about.honors.text'+(3+index*5))}}
              </span>
              <span class="honors-text4">
                {{$t('about.honors.text'+(4+index*5))}}
              </span>
            </div>

            <div class="honors-right-item-detail">
              <div class="honors-text5">
                {{$t('about.honors.text'+(5+index*5))}}
              </div>
              <div class="honors-detail-bottom">
                <div class="honors-right-item-add">
                  +
                </div>
                <span class="honors-text6">
                              {{$t('about.honors.text'+(6+index*5))}}
                            </span>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
    <div class="icon">
      <div class="icon-text2">
        {{$t('home.icon.text2')}}
      </div>
      <div class="icon-text3">
        {{$t('home.icon.text3')}}
      </div>
      <div class="icon-go" @click="toContact">
        <img src="../assets/img/go2.png">
      </div>
    </div>
  </div>
</template>

<script>
import VueCountUp from 'vue-countupjs'
import {slider, slideritem} from "vue-concise-slider";
export default {
  name: "about",
  components: {
    VueCountUp,
    slider,
    slideritem
  },
  data(){
    return{
      showNum:false,
      currentPage:0,
      // //滑动配置[obj]
      options: {
        currentPage: 0,
        effect: 'fade',
        loop: true,
        pagination:false,
      },
      listData: ['1997','2009','2016','2023'],
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll, true);
    if(this.$route.query.honors){
      document.querySelector('#app').scrollTop=3664
    }
  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll,true);
  },
  methods:{
    toContact(){
      this.$router.push('/contact')
    },
    slidePrev () {
      this.$refs.slider.$emit('slidePre')
    },
    slideNext () {
      this.$refs.slider.$emit('slideNext')
    },
    slide (data) {
      this.currentPage=data.currentPage
    },
    handleScroll () {
      let box=document.querySelector('#app');
      let honors=document.querySelector('.honors').getBoundingClientRect().top
      let honorsHeight=(document.querySelector('.honors').offsetHeight-document.body.clientHeight/2)*-1;
      if(honors<0&&honors>honorsHeight){
        document.querySelector('.honors-left').classList.add('honors-left-fixed')
      }else{
        document.querySelector('.honors-left').classList.remove('honors-left-fixed')
      }
      let scrollTop = box.scrollTop;


      if(!this.showNum){
        let panda=document.querySelector('.pandaMCN-cont')
        if(scrollTop>panda.offsetTop-panda.offsetHeight){
          this.showNum=true
        }
      }

    }
  }
}
</script>

<style scoped lang="less">
.cont {

.years {
  width: 100%;
  box-sizing: border-box;
  height: 1080px;
  background: url('../assets/case/case.png') no-repeat;
  background-size: cover;
  padding-top: 208px;

.years-text1 {
  width: 1105px;
  height: 283px;
  background: #F7941F;
  color: white;
  font-size: 110px;
  text-align: center;
  line-height: 283px;
  margin-bottom: 30px;
}

.years-text2 {
  font-size: 90px;
  font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
  font-weight: 800;
  color: #FFFFFF;
  margin-left: 147px;
  margin-bottom: 178px;
}

.years-box {
  padding: 0 60px 0 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;

.years-text3 {
  width: 1179px;
  height: 264px;
  font-size: 30px;
  font-family: Source Han Sans SC-Medium, Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
}

}
}
  .pandaMCN{
    padding-top: 153px;
    padding-left: 115px;

    background: #3B3B3B;
    box-sizing: border-box;
    .pandaMCN-title{
      font-size: 110px;
      font-family: Malgun Gothic-Bold, Malgun Gothic;
      font-weight: bold;
      color: #FFFFFF;
    }
    .pandaMCN-text3{
      width: 1688px;
      font-size: 40px;
      font-family: Source Han Sans SC-Medium, Source Han Sans SC;
      font-weight: 500;
      color: #FFFFFF;
      margin: 119px auto 114px;
    }

    .pandaMCN-cont{
      background-image: url("../assets/about/profile.png");
      background-repeat: no-repeat;
      background-size: 1338px 1538px;
      background-position: right top;
      padding-top: 220px;
      padding-left: 150px;
      box-sizing: border-box;
      height: 1540px;
      .pandaMCN-text{
        display: flex;
        .pandaMCN-text1{
          font-size: 60px;
          font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
          font-weight: 800;
          color: white;
        }
        .pandaMCN-text2{
          margin-left: 40px;
          padding: 5px 50px;
          font-size: 60px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
.memorabilia{
  padding-top: 246px;
  background: #3B3B3B;
  .memorabilia-title{
    width: 1133px;
    height: 196px;
    background: #F7941F;
    font-size: 110px;
    font-family: Malgun Gothic-Bold, Malgun Gothic;
    font-weight: bold;
    color: #FFFFFF;
   text-align: center;
  }
  .memorabilia-text{
    font-size: 40px;
    font-family: Malgun Gothic-Bold, Malgun Gothic;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 329px;
    margin-left: 155px;
  }
  .memorabilia-num{
    display: flex;
    align-items: center;
    justify-content: center;
    .memorabilia-num-btn{
      display: flex;
      align-items: center;
      font-size: 30px;
      font-family: Malgun Gothic-Regular, Malgun Gothic;
      font-weight: 400;
      color: #FFFFFF;

      img{
        width: 56px;
        height: 56px;
        cursor: pointer;
      }

    }
    .memorabilia-num-cont{
      width: 1000px;
      height: 564px;
      .slider-text{
        font-size: 390px;
        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
        font-weight: 400;
        color: #FFFFFF;
        background: #3B3B3B;
      }
    }
  }
  .memorabilia-index{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .memorabilia-bg-aictive{
.memorabilia-index-img{
  opacity: 1 !important;
}
      .bg-aictive{
        background: linear-gradient(360deg, rgba(247, 148, 31, .8) 0%, rgba(247, 148, 31, 0) 100%) !important;
      }
      .bg-aictive1{
        background: linear-gradient(360deg, rgba(247, 148, 31, .7) 0%, rgba(247, 148, 31, 0) 100%) !important;
      }
    }
    .memorabilia-index-item{
      position: relative;
      width: 25%;
      display: flex;
      justify-content: center;
      transition: all 1s;
      .memorabilia-bg{
        transition: all 1s;
        margin: 0 8px;
        width: 2px;
        height: 317px;
        background: linear-gradient(360deg, #FFFFFF 0%, rgba(128,128,128,0) 100%);
      }

    }
    .memorabilia-index-img{
      opacity: 0;
      position: absolute;
      transition: all .8s;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      img{
        -webkit-user-drag: none;
        cursor: pointer;
        width: 97px;
        height: 97px;
      }
    }
  }
}
.honors{
  display: flex;
  justify-content: right;
  padding-right: 260px;
  box-sizing: border-box;
  position: relative;
  .honors-left{
    transition: all 1s;
    position: absolute;
    left: 0;
    top: 0px;
    height: 1191px;
    width: 980px;
    .honors-text1{
      position: relative;
      z-index: 1;
      line-height: 150px;
      font-size: 130px;
      font-family: Malgun Gothic-Bold, Malgun Gothic;
      font-weight: bold;
      color: #FFFFFF;
      white-space: pre-line;
      -webkit-text-stroke: 5px #707070;
      text-stroke: 5px #707070;
      text-align: center;
      margin-top: 413px;
    }
    img{
      width: 538px;
      height: 1191px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .honors-left-fixed{
    position: fixed;
  }
  .honors-left-none{
    opacity: 0;
  }
  .honors-right{
    .honors-right-item{
      position: relative;
      width: 680px;
      height: 680px;
      background: #3B3B3B;
      margin-top: 82px;
      .honors-right-item-box:hover .honors-right-item-detail{
        opacity: 1;
      }
      .honors-right-item-box{
        padding: 45px 60px 0;
        box-sizing: border-box;
        transition: all 1s;
        position: relative;
        height: 635px;
        //&:hover{
        // opacity: 0;
        //}

        }
      .honors-right-item-detail{
        width: 100%;
        padding: 45px 60px 0;
        box-sizing: border-box;
        background: red;
        transition: all 1s;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        background: #3B3B3B;
        height: 100%;
        .honors-text5{
          font-size: 30px;
          font-family: Malgun Gothic-Regular, Malgun Gothic;
          font-weight: 400;
          color: #FFFFFF;
        }
        .honors-detail-bottom{
          position: absolute;
          bottom: 0;
          display: flex;
          justify-content: space-between;

          .honors-text6{
            margin-left: 29px;
            font-size: 30px;
            font-family: Malgun Gothic-Regular, Malgun Gothic;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
      .honors-text2{
        font-size: 30px;
        font-family: Malgun Gothic-Regular, Malgun Gothic;
        font-weight: 400;
        color: #FFFFFF;
        height: 100px;
      }
      .honors-right-item-add{
        flex-shrink: 0;
        width: 51px;
        height: 51px;
        background: #F7941F;
        border-radius: 50%;
        text-align: center;
        line-height: 45px;
        font-size: 32px;
        color: white;
      }
      .honors-right-item-img{
        position: relative;
        width: 326px;
        margin: 0px auto 0;
        .honors-right-item-add{
          position: absolute;
          right: 0;
          top: 0;
        }
        img{
          width: 326px;
          height: 326px;
        }
      }
      .honors-text-box{
        display: flex;
        align-items: end;
        justify-content: space-between;
        .honors-text3{
          font-size: 120px;
          font-family: Malgun Gothic-Regular, Malgun Gothic;
          font-weight: 400;
          color: #F7941F;
        }
        .honors-text4{
          font-size: 30px;
          font-family: Malgun Gothic-Regular, Malgun Gothic;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
  .icon{
    padding: 324px 45px 68px;
    position: relative;
    .icon-go{
      &:hover{
        opacity: 1;
      }
      cursor: pointer;
      transition: all .8s;
      opacity: 0;
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);
      img{
        width: 248px;
        height: 248px;

      }
    }
    .icon-text{
      font-size: 80px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #222222;
    }
    .icon-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 120px 0;
    }
    .icon-text2:hover +.icon-go{
      opacity: 1;
    }
    .icon-text3:hover +.icon-go{
      opacity: 1;
    }
    .icon-text2{
      font-size: 55px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #AAAAAA;
      text-align: center;
    }
    .icon-text3{
      text-align: center;
      font-size: 111px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #BBBBBB;
      margin-top: 16px;
      margin-bottom: 200px;
    }
  }
}
</style>
