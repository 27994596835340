<template>
  <div class="cont">
  <div class="video-box">
    <!-- <video src="/media/video.d839f680.mp4" autoplay loop muted></video> -->
   <video src="../assets/video.mp4" autoplay loop muted></video>
  </div>

    <div class="years">
      <p class="years-text1">
        {{$t('home.years.text1')}}
      </p>
      <p class="years-text2">
        {{$t('home.years.text2')}}
      </p>
      <div class="years-box">
        <a href="#global" @click="global">
          <img src="../assets/img/bottom.png" style="width: 72px;height: 72px">
        </a>
        <p class="years-text3" v-html="$t('home.years.text3')">

        </p>
      </div>
    </div>
    <div id="global" class="global global1">
      <img class="global-img1" src="../assets/img/global1.png" alt="">
      <div class="global-text1" v-text="$t('home.global.text1')">

      </div>
    </div>
    <div class="global global2">
      <img src="../assets/img/global2.jpg" alt="" class="global-img2">
      <div class="global-text2">
        {{$t('home.global.text2')}}
      </div>
      <div class="global-text3" :class="[$i18n.locale=='cn'?'global-text3s':'']">
        <p style="position: relative;z-index: 2">
          {{$t('home.global.text3')}}
        </p>

      </div>
    </div>
    <div class="list">
      <div class="list-text text-stroke">
        <p>
          {{$t('home.list.text1')}}
        </p>

      </div>
      <div class="list-text text-entity">
        <p>
            {{$t('home.list.text2')}}
        </p>

      </div>
      <div class="list-text text-stroke text-hover">
        <p>
          {{$t('home.list.text3')}}
        </p>

      </div>
      <div class="list-text text-entity text-hover-stroke" style="margin-bottom: 0">
        <p>
          {{$t('home.list.text4')}}
        </p>

      </div>
    </div>
    <div class="memorabilia">
      <div class="memor-title">
        <span class="memor-title1">
          {{$t('home.memorabilia.name')}}
        </span>
        <span style="cursor: pointer" @click="toAbout">
          >>
        </span>
      </div>
      <div class="memor-box">
        <div class="memor-index">
          <p>
            {{$t('home.memorabilia.now.text1')}}
          </p>

        </div>
        <div class="memor-text">
          <span class="memor-text1">
            {{$t('home.memorabilia.now.text2')}}
          </span>
          <p class="memor-text2">
              {{$t('home.memorabilia.now.text1')}}
          </p>
        </div>
        <div class="memor-img">
          <img src="../assets/img/meor1.png" class="memor-img1">
          <img src="../assets/img/go1.png" class="memor-img2" @click="toAbout">
        </div>
      </div>

      <div class="memor-box">
        <div class="memor-index">
          <p>
            {{$t('home.memorabilia.2016.text1')}}
          </p>

        </div>
        <div class="memor-text">
          <span class="memor-text1">
            {{$t('home.memorabilia.2016.text2')}}
          </span>
          <p class="memor-text2">
              {{$t('home.memorabilia.2016.text1')}}
          </p>
        </div>
        <div class="memor-img">
          <img src="../assets/img/meor2.jpg" class="memor-img1">
          <img src="../assets/img/go1.png" class="memor-img2" @click="toAbout">
        </div>
      </div>
      <div class="memor-box">
        <div class="memor-index">
          <p>
            {{$t('home.memorabilia.2009.text1')}}
          </p>

        </div>
        <div class="memor-text">
          <span class="memor-text1">
            {{$t('home.memorabilia.2009.text2')}}
          </span>
          <p class="memor-text2">
              {{$t('home.memorabilia.2009.text1')}}
          </p>
        </div>
        <div class="memor-img">
          <img src="../assets/img/meor3.jpg" class="memor-img1">
          <img src="../assets/img/go1.png" class="memor-img2" @click="toAbout">
        </div>
      </div>
    </div>
    <div class="pandaMCN">
      <div class="pandaMCN-title">
        {{$t('home.pandaMCN.name')}}
      </div>
      <div class="pandaMCN-cont">
        <div class="pandaMCN-text">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="1300" v-if="showNum" />
            {{$t('home.pandaMCN.text1')}}
          </div>
          <div class="pandaMCN-text2" style="background: #222222">
            {{$t('home.pandaMCN.text2')}}
          </div>
        </div>
        <div class="pandaMCN-text" style="margin: 50px 0">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="2600" v-if="showNum" />
            {{$t('home.pandaMCN.text3')}}
          </div>
          <div class="pandaMCN-text2" style="background: #0E653B">
            {{$t('home.pandaMCN.text4')}}
          </div>
        </div>
        <div class="pandaMCN-text">
          <div class="pandaMCN-text1">
            <VueCountUp :delay="1" :start-value="0" :end-value="1" v-if="showNum" />
            {{$t('home.pandaMCN.text5')}}
          </div>
          <div class="pandaMCN-text2" style="background: #F7941F">
            {{$t('home.pandaMCN.text6')}}
          </div>
        </div>
      </div>
      <div class="pandaMCN-title" style="text-align: left;padding-left: 74px">
        {{$t('home.pandaMCN.name1')}}
      </div>
    </div>
    <div class="map">
      <img src="../assets/img/enMap.png" class="map-img">
      <div class="map-title">
        <img src="../assets/img/tiktop.png" style="margin-right: 10px">
        <span>
          {{$t('home.map.text1')}}
        </span>
      </div>
      <div class="map-text2" v-html="$t('home.map.text2')">

      </div>
      <div class="map-text2" v-html="$t('home.map.text3')">

      </div>
      <div class="map-text4">
        {{$t('home.map.text4')}}
      </div>
      <div class="map-bg"></div>
      <img class="map-img1" src="../assets/img/map1.jpg">
      <div class="map-text2" style="text-align: left;padding-left: 75px;margin-left: 0">
        {{$t('home.map.text5')}}
      </div>
      <img src="../assets/img/go.png" class="map-go" @click="toBusiness">
    </div>
    <div class="icon">
      <div class="icon-text">
        {{$t('home.icon.text1')}}
      </div>
      <div class="icon-img">
        <div><img src="../assets/img/icon1.png" ></div>
        <div><img src="../assets/img/icon2.png" ></div>
        <div><img src="../assets/img/icon3.png" ></div>
        <div>  <img src="../assets/img/icon4.png"  ></div>
        <div> <img src="../assets/img/icon5.png" ></div>
        <div> <img src="../assets/img/icon6.png" ></div>
        <div><img src="../assets/img/icon14.png" ></div>
        <div>  <img src="../assets/img/icon7.png" ></div>
        <div> <img src="../assets/img/icon8.png" ></div>
        <div>   <img src="../assets/img/icon9.png" ></div>
        <div>     <img src="../assets/img/icon10.png" ></div>
        <div>  <img src="../assets/img/icon11.png" ></div>
        <div>        <img src="../assets/img/icon12.png" ></div>
        <div><img src="../assets/img/icon13.png" ></div>
        <div> <img src="../assets/img/icon15.png" ></div>
      </div>
      <div class="icon-text2">
        {{$t('home.icon.text2')}}
      </div>
      <div class="icon-text3">
        {{$t('home.icon.text3')}}
      </div>
      <div class="icon-go" @click="toContact">
        <img src="../assets/img/go2.png">
      </div>
    </div>

  </div>

</template>

<script>
import VueCountUp from 'vue-countupjs'
export default {
  components: {
    VueCountUp,
  },
  data(){
    return{
      showNum:false
    }
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods:{
    toBusiness(){
      this.$router.push('/business')
    },
    toContact(){
      this.$router.push('/contact')
    },
    toAbout(){
      this.$router.push({
        path:'/about',
        query:{
          honors:true
        }
      })
    },
    global(){
      // let url=window.location.href;
      // let newUrl=url.slice(0, (url.indexOf('global')))
      // console.log(newUrl);
      // console.log(this.$route);
      // window.history.replaceState({}, 0, '/1')
    },
    handleScroll () {
      if(!this.showNum){
        let box=document.querySelector('#app')
        let scrollTop = box.scrollTop;
        let panda=document.querySelector('.pandaMCN-cont')
        if(scrollTop>panda.offsetTop-panda.offsetHeight){
          this.showNum=true
        }
      }

    }

  }
}

</script>
<style lang="less" scoped>

.cont{
  width: 1920px;
  .video-box{
    video{
      vertical-align: top;
      width: 100%;
    }

    width: 100%;
  }
}
.years{
  width: 1920px;
  box-sizing: border-box;
  //height: 1080px;
  background:url('../assets/img/year.jpg') no-repeat ;
  background-size: 1920px 1080px;
  padding-top: 80px;
  .years-text1{
    width: 1105px;
    height: 283px;
    background: #F7941F;
    color: white;
    font-size: 110px;
    text-align: center;
    line-height: 283px;
    margin-bottom: 77px;
  }
  .years-text2{
    font-size: 90px;
    font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
    font-weight: 800;
    color: #FFFFFF;
    margin-left: 147px;
    margin-bottom: 178px;
  }
  .years-box{
    padding: 0 60px 0 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  .years-text3{
    width: 1179px;
    height: 264px;
    font-size: 30px;
    font-family: Source Han Sans SC-Medium, Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  }
}
.global{
  margin-top: -11px;
  height: 820px;
  background: white;
  position: relative;
  .global-img1{
    position: absolute;
    left: 0;
    top: 0;
    width: 752px;
    height: 1080px;
    z-index: 1;
  }
  .global-img2{
    width: 456px;
    height: 900px;
    position: absolute;
    right: 150px;
    top: -80px;
  }
  .global-text1{
    width: 885px;
    font-size: 40px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    white-space: break-spaces;
    color: #0C0C0C;
  }
  .global-text2{
    font-size: 110px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    z-index: 2;
    position: relative;
  }
  .global-text3{
    margin: 50px 0 0 364px;
    width: 1100px;
    white-space: break-spaces;
    font-size: 28px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 45px;
    position: relative;
    &:after{
     // content:'';
      position: absolute;
      width: 560px;
      height: 50px;
      background: #F7941F;
      bottom: -5px;
      right: 280px;
    }
  }
  .global-text3s{
    &:after{
      top: 0;
    }
  }
}
.global1{
  width: 100%;
  display: flex;
  height: 900px;
  align-items: center;
  justify-content: right;
}
.global2{
  background: #3B3B3B;
  padding-top: 270px;
  box-sizing: border-box;
}
.list{
  padding: 250px 0 200px;
  .list-text{
    font-size: 60px;
    font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
    font-weight: 800;
    text-align: center;
    margin-bottom: 50px;
    cursor: pointer;
    transition: all .5s;
    p{
      position: relative;
      z-index: 1;
    }
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 346px;
      height: 314px;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all .5s;
    }
    &:nth-child(1):after{
      left: 160px;
      top: -150px;
      background-image: url("../assets/img/list1.png");
    }
    &:nth-child(2):after{
      right: 150px;
      top: -120px;
      background-image: url("../assets/img/list2.png");
    }
    &:nth-child(3):after{
      left: 43%;
      top: -45px;
      background-image: url("../assets/img/list3.png");
    }
    &:nth-child(4):after{
      left: 160px;
      top: -150px;
      background-image: url("../assets/img/list4.png");
    }
  }
  .text-stroke{
    -webkit-text-stroke: 1px black; //文字描边
    color: white;
    &:hover{
      color:black;
      -webkit-text-stroke: 1px white; //文字描边
    }
    &:hover:after{
      opacity: 1;
    }
  }
  .text-entity{
    &:hover{
      -webkit-text-stroke: 1px black; //文字描边
      color: white;
    }
    &:hover:after{
      opacity: 1;
    }
  }
  .text-hover:hover + .text-hover-stroke p{
    -webkit-text-stroke: 1px white; //文字描边
  }
}
.memorabilia{
  .memor-title{
    display: flex;
    padding: 0 74px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 80px;
    align-items: center;
    .memor-title1{
      font-size: 80px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #222222;
    }
  }
  .memor-box{
  justify-content: space-between;
    display: flex;
    margin-bottom: 67px;
    padding-left: 120px;
    padding-right: 111px;
    box-sizing: border-box;
    .memor-index{
      font-size: 40px;
      font-family: Source Han Sans SC-Medium, Source Han Sans SC;
      font-weight: 500;
      color: #222222;
      position: relative;
      p{
        position: absolute;
        z-index: 2;
      };
      &:after{
        position: absolute;
        content: '';
        width: 157px;
        height: 21px;
        background: #F7941F;
        top: 35px;
        left: 0;
      }
    }
    .memor-text{
      width: 618px;
      .memor-text1{
        font-size: 50px;
        font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
        font-weight: 800;
        color: #222222;
        // word-break: break-all;
      }
      .memor-text2{
        margin-top: 50px;
        font-size: 80px;
        font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
        font-weight: 800;
        color: #222222;
      }
    }
    .memor-img{
      position: relative;
      cursor: pointer;
      &:hover .memor-img2{
        opacity: 1;
      }
      .memor-img1{
        width: 646px;
        height: 615px;
      }
      .memor-img2{
        transition: all .8s;
        opacity: 0;
        position: absolute;
        cursor: pointer;
        left: -60px;
        bottom: 60px;
        width: 160px;
        height: 160px;
      }
    }
  }
}
.pandaMCN{
  margin-bottom: 95px;
  .pandaMCN-title{
    font-size: 70px;
    font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
    font-weight: 800;
    color: #0C0C0C;
    margin-bottom: 100px;
    text-align: center;
  }
.pandaMCN-cont{
  background-image: url("../assets/img/pandaMCN.jpg");
  background-repeat: no-repeat;
  background-size: 559px 784px;
  background-position: 1136px top;
  padding-top: 220px;
  padding-left: 150px;
  box-sizing: border-box;
  height: 1000px;
  .pandaMCN-text{
    display: flex;
    .pandaMCN-text1{
      font-size: 60px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #222222;
    }
    .pandaMCN-text2{
      margin-left: 40px;
      padding: 5px 50px;
      font-size: 60px;
      font-family: Source Han Sans SC-Bold, Source Han Sans SC;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
}
.map{
  background: #3B3B3B;
  position: relative;
  padding-top: 65px;
  height: 2020px;
  padding-right: 48px;
  box-sizing: border-box;
  .map-img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .map-img1{

    position: absolute;
    width: 724px;
    height: 906px;
    right: 77px;
    bottom: -150px;
  }
  .map-bg{
    width: 786px;
    height: 1174px;
    background: #F7941F;
    position: absolute;
    bottom: 0;
    left: 333px;
  }
  .map-title{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 90px;
    img{
      width: 51px;
      height: 51px;
    }
    font-size: 20px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: right;
  }
  .map-text2{
    width: 1135px;
    margin-left: 730px;
    position: relative;
    z-index: 1;
    white-space: pre-line;
    text-align: right;
    height: 232px;
    font-size: 40px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 160px;
  }
  .map-text4{
    position: relative;
    z-index: 1;
    font-size: 100px;
    font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
    font-weight: 800;
    color: #FFFFFF;
    margin-left: 149px;
    margin-bottom: 276px;
  }
  .map-go{
    position: relative;
    z-index: 1;
    width: 73px;
    height: 55px;
    left: 400px;
    cursor: pointer;
  }
}
.icon{
  padding: 324px 45px 68px;
  position: relative;
  .icon-go{
    &:hover{
      opacity: 1;
    }
    cursor: pointer;
    transition: all .8s;
    opacity: 0;
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    img{
      width: 248px;
      height: 248px;

    }
  }
  .icon-text{
    font-size: 80px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #222222;
  }
  .icon-img{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 120px 0;
    div{
      //height: 150px;
      width: 20%;
      text-align: center;

    }
    div:nth-child(1) img{
      width: 290px
    }
    div:nth-child(2) img{
      width: 325px
    }
    div:nth-child(3) img{
      width: 367px
    }
    div:nth-child(4) img{
      width: 288px
    }
    div:nth-child(5) img{
      width: 337px
    }
    div:nth-child(6) img{
      width: 223px;margin-top: 40px
    }
    div:nth-child(7) img{
      width: 198px
    }
    div:nth-child(8) img{
      width: 198px
    }
    div:nth-child(9) img{
      width: 326px
    }
    div:nth-child(10) img{
      width: 332px;margin-top: 20px
    }
    div:nth-child(11) img{
      width: 300px
    }
    div:nth-child(12) img{
      width: 308px
    }
    div:nth-child(13) img{
      width: 308px
    }
    div:nth-child(14) img{
      width: 315px
    }
    div:nth-child(15) img{
      width: 166px
    }
  }
  .icon-text2:hover +.icon-go{
    opacity: 1;
  }
  .icon-text3:hover +.icon-go{
    opacity: 1;
  }
  .icon-text2{
    font-size: 55px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #AAAAAA;
    text-align: center;
  }
  .icon-text3{
 text-align: center;
    font-size: 111px;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;
    font-weight: 400;
    color: #BBBBBB;
margin-top: 16px;
margin-bottom: 200px;
  }
}
</style>
