<template>
  <div class="cont">
    <div class="left">
      <img src="../assets/contact/bg.png" />
      <div class="text1">
        {{ $t("contact.text1") }}
      </div>
    </div>
    <div class="right">
      <img src="../assets/contact/path.png" alt="">
      <span class="text2">
        {{ $t("footer.CONTACT.text1") }}
      </span>
      <br>
      <!-- <span class="text2">
        {{ $t("footer.CONTACT.text2") }}
      </span> -->
      <!-- <div class="text2">
        {{$t('contact.text2')}}
      </div>
      <div class="text2">
        {{$t('contact.text3')}}
      </div>
      <div class="text2">
        {{$t('contact.text4')}}
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
};
</script>

<style scoped lang="less">
.cont {
  display: flex;
  height: 1182px;
  .left {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 488px;
      height: 1182px;
    }
    .text1 {
      position: relative;
      z-index: 1;
      margin-top: 332px;
      padding-left: 154px;
      box-sizing: border-box;
      white-space: pre-line;
      font-size: 130px;
      font-family: Malgun Gothic-Bold, Malgun Gothic;
      font-weight: bold;
      color: #ffffff;
      -webkit-text-stroke: 5px #707070;
      text-stroke: 5px #707070;
    }
  }
  .right {
    padding-top: 701px;
    margin-left: 339px;
    display: flex;
    flex-direction: column;
    img {
      width: 66px;
      height: 50px;
    }
    .text2 {
      margin-top: 60px;
      font-size: 40px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #222222;
    }
  }
}
</style>
