<template>
  <div class="cont">
    <div class="years">
      <p class="years-text1">
        {{$t('case.years.text1')}}
      </p>
      <p class="years-text2">
        {{$t('case.years.text2')}}
      </p>
    </div>
    <div class="social">
      <div class="social-text1">
        {{$t('case.social.text1')}}
      </div>
      <div class="social-text2" v-html="$t('case.social.text2')">

      </div>
      <div class="social-text3" v-html="$t('case.social.text3')">

      </div>
      <div class="social-img">
        <img src="../assets/case/socialImg.png" >
      </div>
      <div class="social-box">
        <div class="social-box-item">
          <p> {{$t('case.social.text4')}}</p>
          <img src="../assets/case/social1.png" alt="" style="width: 280px;height: 501px">
        </div>
        <div class="social-box-item" style="margin-top: 59px">
          <p> {{$t('case.social.text5')}}</p>
          <img src="../assets/case/social2.png" alt="" style="width: 250px;height: 548px">
        </div>
        <div class="social-box-item">
          <p> {{$t('case.social.text6')}}</p>
          <img src="../assets/case/social3.png" alt="" style="width: 288px;height: 512px">
        </div>
      </div>
    </div>
    <div class="kol-box">
      <div class="kol-title">
        {{$t('case.kol.text1')}}
      </div>
      <div class="kol-title1">
        {{$t('case.kol.text2')}}
      </div>
      <div class="kol-box-cont">
        <div class="kol-box-left">
          <div class="kol-box-left-img">
            <img src="../assets/case/left.png" @click="slidePrev" style="margin-right: 31px">
            <img src="../assets/case/right.png" @click="slideNext">
          </div>
          <div class="kol-text" ref="text">
            {{$t('case.kol.imgText'+currentPage)}}
          </div>
        </div>
        <div class="kol-box-right">
          <slider ref="slider" :options="options" @slide="slide">
            <slideritem  v-for="(item,index) in listData" :key="index" >
              <div>
                <img :src="item.url" alt="">
              </div>
            </slideritem>
          </slider>
        </div>
      </div>
    </div>

    <div class="tiktok">
      <div class="tiktok-title">
        {{$t('case.tiktok.text1')}}
      </div>
      <div class="tiktok-box">
        <div class="tiktok-text2">
          {{$t('case.tiktok.text2')}}
        </div>
        <img class="tiktok-img" src="../assets/case/tiktok1.png" alt="">
        <div class="tiktok-text3">{{$t('case.tiktok.text3')}}</div>
      </div>
      <div class="tiktok-box tiktok-box1">
        <div class="tiktok-text2">
          {{$t('case.tiktok.text4')}}

        </div>
        <img class="tiktok-img" src="../assets/case/tiktok2.png" alt="" v-if="$i18n.locale=='en'">
        <img class="tiktok-img" src="../assets/case/tiktokcn2.png" alt="" v-else-if="$i18n.locale=='cn'">
        <img class="tiktok-img" src="../assets/case/tiktokEn2.png" alt="" v-else>
        <div class="tiktok-text3">{{$t('case.tiktok.text5')}}</div>
      </div>
    </div>

    <div class="icon">
      <div class="icon-text2">
        {{$t('home.icon.text2')}}
      </div>
      <div class="icon-text3">
        {{$t('home.icon.text3')}}
      </div>
      <div class="icon-go" @click="toContact">
        <img src="../assets/img/go2.png">
      </div>
    </div>
  </div>
</template>

<script>
import {slider, slideritem} from "vue-concise-slider";
export default {
  name: "case",
  components: {
    slider,
    slideritem
  },
  data () {
    return {
      currentPage:0,
      // //滑动配置[obj]
      options: {
        currentPage: 0,
        effect: 'fade',
        pagination:false,
      },
      listData: [
        {
          url:require('../assets/case/kol1.png'),
        },
        {
          url: require('../assets/case/kol2.png'),
        }
      ],
    }
  },

methods:{
  toContact(){
    this.$router.push('/contact')
  },
  slide (data) {
    console.log(this.$refs.text);
    this.$refs.text.classList.add('kol-text-opacity')
    setTimeout(()=>{
      this.$refs.text.classList.remove('kol-text-opacity')
      this.currentPage=data.currentPage
    },700)
  },
  slidePrev () {
    this.$refs.slider.$emit('slidePre')
  },
  slideNext () {
    this.$refs.slider.$emit('slideNext')
  },

}
}
</script>

<style scoped lang="less">
.cont {
  .years {
    width: 100%;
    box-sizing: border-box;
    height: 1080px;
    background: url('../assets/case/case.png') no-repeat;
    background-size: cover;
    padding-top: 208px;

    .years-text1 {
      width: 1105px;
      //height: 283px;
      background: #F7941F;
      color: white;
      font-size: 110px;
      text-align: center;
      margin-bottom: 30px;
    }

    .years-text2 {
      font-size: 90px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      line-height: 120px;
      color: #FFFFFF;
      margin-left: 147px;
      //margin-bottom: 178px;
    }

    .years-box {
      padding: 0 60px 0 74px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .years-text3 {
        width: 1179px;
        height: 264px;
        font-size: 30px;
        font-family: Source Han Sans SC-Medium, Source Han Sans SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
  .social{
    padding-top: 115px;
    position: relative;
    .social-text1{
padding-left: 104px;
      font-size: 60px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #222222;

    }
    .social-text2{
      width: 1137px;
      height: 187px;
      background: #F7941F;
      position: relative;
      z-index: 1;
     padding-top: 27px ;
      text-align: center;
      line-height: 70px;
      box-sizing: border-box;
      font-size: 40px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;
margin: 90px 0 58px 250px;

    }
    .social-text3{
      width: 878px;
      position: relative;
      z-index: 1;
      height: 176px;
      white-space: pre-line;
      font-size: 30px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #222222;
    margin-left: 337px;
    }
    .social-img{
      width: 603px;
      height: 741px;
      position: absolute;
      right: 0;
      top: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .social-box{
      margin-top: 100px;
      display: flex;
      padding: 0 99px;
      box-sizing: border-box;
      justify-content: space-between;
      .social-box-item{
        font-size: 40px;
        font-family: Source Han Sans SC-Regular, Source Han Sans SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
        p{
          margin-bottom: 37px;
        }
      }
    }
  }
  .kol-box{

    background: #3B3B3B;
    margin-top: -195px;
    .kol-title{
      padding-top: 433px;
      margin-bottom: 111px;
      font-size: 80px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #FFFFFF;
      text-align: center;
    }
    .kol-title1{
      font-size: 40px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
    .kol-box-cont{
      display: flex;
      align-items: end;
      margin-top: 142px;
      padding-left: 137px;
      box-sizing: border-box;
      .kol-box-left{
        width: 887px;
        height: 597px;
        padding: 54px 48px;
        box-sizing: border-box;
        background: #F7941F;
        .kol-box-left-img{
          display: flex;
          img{
            width: 58px;
            height: 58px;
            cursor: pointer;
          }
        }
        .kol-text{
          overflow: hidden;
          transition: all .7s;
          margin-top: 63px;
          width: 769px;
          font-size: 30px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
          white-space: pre-line;
        }
        .kol-text-opacity{
          margin-top: 80px;
          opacity: 0;
        }
      }
      .kol-box-right{
        width: 908px;
        height: 794px;
        img{
          width: 908px;
          height: 794px;
          vertical-align: top;
        }
      }
    }
  }
  .tiktok{
    padding-bottom: 120px;
    padding-top: 171px;
    .tiktok-title{
      font-size: 80px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #222222;
      text-align: right;
      padding-right: 174px;
    }
    .tiktok-box{
      margin-top: 212px;
      position: relative;
      .tiktok-img{
        width: 476px;
        height: 968px;
        position: absolute;
        left: 181px;
        top: -60px;
      }
      .tiktok-text2{
        width: 951px;
        white-space: pre-line;
        font-size: 80px;
        font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
        font-weight: 800;
        color: #222222;
        margin-left: 778px;
        box-sizing: border-box;
      }
      .tiktok-text3{
        margin-top: 143px;
        padding-left: 778px;
        box-sizing: border-box;
        width: 1920px;
        height: 186px;
        line-height: 186px;
        background: #F7941F;
        font-size: 80px;
        font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
        font-weight: 800;
        color: #FFFFFF;
      }
    }
    .tiktok-box1{
      margin-top: 466px;
      .tiktok-img{
        left: 1308px;
        top: 0px;
      }
      .tiktok-text2{
        margin-left: 180px;
      }
      .tiktok-text3 {
        background: #0E653B;
        padding-left: 180px;
      }
    }
  }
  .icon{
    padding: 324px 45px 68px;
    position: relative;
    .icon-go{
      &:hover{
        opacity: 1;
      }
      cursor: pointer;
      transition: all .8s;
      opacity: 0;
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);
      img{
        width: 248px;
        height: 248px;

      }
    }
    .icon-text{
      font-size: 80px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #222222;
    }
    .icon-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 120px 0;
    }
    .icon-text2:hover +.icon-go{
      opacity: 1;
    }
    .icon-text3:hover +.icon-go{
      opacity: 1;
    }
    .icon-text2{
      font-size: 55px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #AAAAAA;
      text-align: center;
    }
    .icon-text3{
      text-align: center;
      font-size: 111px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #BBBBBB;
      margin-top: 16px;
      margin-bottom: 200px;
    }
  }
}
</style>
