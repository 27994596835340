<template>
<div class="cont">
  <div class="years">
    <p class="years-text1">
      {{$t('business.years.text1')}}
    </p>
    <p class="years-text2">
      {{$t('business.years.text2')}}
    </p>
    <div class="years-box">
      <a href="#box" @click="clear">
        <img src="../assets/img/bottom.png" style="width: 72px;height: 72px">
      </a>
    </div>
  </div>
  <div class="box" id="box">
    <div class="box-top">
      <div class="box-rop-left">
        <p>
          {{$t('business.box.text1')}}
        </p>
        <p v-html="$t('business.box.text2')">

        </p>
      </div>
      <div class="box-rop-right">
        <img src="../assets/business/box.png">
      </div>
    </div>
    <div class="box-bottom">
      <div class="box-bottom-item">
        {{$t('business.box.text3')}}
      </div>
      <div class="box-bottom-item">
        {{$t('business.box.text4')}}
      </div>
    </div>
  </div>
  <div class="list">
    <div class="list-text text-stroke">
      <p>
        {{$t('business.list.text1')}}
      </p>

    </div>
    <div class="list-text text-entity">
      <p>
        {{$t('business.list.text2')}}
      </p>

    </div>
    <div class="list-text text-stroke">
      <p>
        {{$t('business.list.text3')}}
      </p>

    </div>
    <div class="list-text text-entity" >
      <p>
        {{$t('business.list.text4')}}
      </p>
    </div>
    <div class="list-text text-stroke">
      <p>
        {{$t('business.list.text5')}}
      </p>

    </div>
    <div class="list-text text-entity">
      <p>
        {{$t('business.list.text6')}}
      </p>

    </div>
    <div class="list-text text-stroke">
      <p>
        {{$t('business.list.text7')}}
      </p>

    </div>
  </div>
  <div class="media">
    <div class="media-title">
      {{$t('business.media.text1')}}
    </div>
    <div class="media-btn">
      <div class="media-btn-item" style="margin-right: 20px">
        <img src="../assets/business/left.png" alt="" @click="slidePrev">
      </div>
      <div class="media-btn-item" @click="slideNext">
        <img src="../assets/business/right.png" alt="">
      </div>
    </div>
    <div class="media-img">
      <!-- 配置slider组件 -->
      <slider ref="slider" :options="options" >
        <slideritem  v-for="(item,index) in listData" :key="index" :style="item.style">
          <div>
            <img :src="item.url" alt="">
          </div>
          <p class="slider-text">{{$t('business.media.imgText'+index)}}</p>
        </slideritem>
      </slider>
    </div>
  </div>
  <div class="release">
    <img class="release-img" src="../assets/business/release.png">
    <p>
      {{$t('business.release')}}
    </p>
  </div>
  <div class="icon">
    <div class="icon-text2">
      {{$t('home.icon.text2')}}
    </div>
    <div class="icon-text3">
      {{$t('home.icon.text3')}}
    </div>
    <div class="icon-go" @click="toContact">
      <img src="../assets/img/go2.png">
    </div>
  </div>
</div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
export default {
  name: "business",
  components: {
    slider,
    slideritem
  },
  data () {
    return {
      // //滑动配置[obj]
      options: {
        currentPage: 0,
        loop:true,//循环滚动
        loopedSlides:3,
        pagination:false,
        speed:700
      },
      listData: [

          ],

    }
  },
  mounted(){
    var html_ = document.getElementsByTagName('html')[0];
    // 缩放比例
    let scale=1920/document.body.offsetWidth;
    // 根字体大小
    let size=parseFloat(html_.style.fontSize)
    let remW=(695/scale/size)+'rem';
    let remR=(46/scale/size)+'rem';
    for(let i=1;i<15;i++){
      this.listData.push( {
        url: require(`../assets/business/carousel/mediaImg${i}.png`),
        style: {
          'width': remW,
          'margin-right': remR
        }
      })
    }
  },
  methods:{
    toContact(){
      this.$router.push('/contact')
    },
    slidePrev () {
      this.$refs.slider.$emit('slidePre')
    },
    slideNext () {
      this.$refs.slider.$emit('slideNext')
    },
    clear(){

    }
  }
}
</script>

<style scoped lang="less">
.cont{
.years{
  width: 100%;
  box-sizing: border-box;
  height: 1080px;
  background:url('../assets/business/banner.png') no-repeat ;
  background-size: cover;
  padding-top: 208px;
.years-text1{
  width: 1105px;
  height: 283px;
  background: #F7941F;
  color: white;
  font-size: 110px;
  text-align: center;
  line-height: 283px;
  margin-bottom: 30px;
}
.years-text2{
  font-size: 90px;
  font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
  font-weight: 800;
  color: #FFFFFF;
  margin-left: 147px;
  margin-bottom: 178px;
}
.years-box{
  padding: 0 60px 0 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
.years-text3{
  width: 1179px;
  height: 264px;
  font-size: 30px;
  font-family: Source Han Sans SC-Medium, Source Han Sans SC;
  font-weight: 500;
  color: #FFFFFF;
}
}
}
  .box{
    margin-top: -100px;
    .box-top{
      display: flex;
      justify-content: right;
      .box-rop-left{
        width: 1240px;
        height: 1104px;
        padding: 209px 130px 0;
        box-sizing: border-box;
        background: #3B3B3B;
        p:nth-child(1){
margin-bottom: 243px;
          font-size: 80px;
          font-family: Source Han Sans SC-Bold, Source Han Sans SC;
          font-weight: bold;
          color: #FFFFFF;
        }
        p:nth-child(2){
          font-size: 30px;
          font-family: Source Han Sans SC-Regular, Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .box-rop-right{
        width: 382px;
        height: 1104px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .box-bottom{
      display: flex;
      padding-left: 100PX;
      margin-top: -100px;
      .box-bottom-item{
        width: 628px;
        height: 474px;
        background: #FFFFFF;
        padding: 132px 69px;
        box-sizing: border-box;
        font-size: 40px;
        font-family: Source Han Sans SC-Medium, Source Han Sans SC;
        font-weight: 500;
        color: #0C0C0C;

      }
      .box-bottom-item:nth-child(1){
        border: 1px solid #707070;
        margin-right: 62px;
      }
      .box-bottom-item:nth-child(2){
        border: none;
        background: #F7941F;
        color: white;
      }
    }
  }
  .list{
    padding: 250px 0 200px;
    .list-text{
      font-size: 60px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      text-align: center;
      margin-bottom: 50px;
      cursor: pointer;
      transition: all .5s;
      p{
        position: relative;
        z-index: 1;
      }
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: 346px;
        height: 314px;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;
        transition: all .5s;
      }
      &:nth-child(1):after{
        left: 160px;
        top: -150px;
        background-image: url("../assets/business/list1.png");
      }
      &:nth-child(2):after{
        right: 150px;
        top: -120px;
        background-image: url("../assets/business/list2.png");
      }
      &:nth-child(3):after{
        left: 40%;
        top: -120px;
        background-image: url("../assets/business/list3.png");
      }
      &:nth-child(4):after{
        left: 160px;
        top: -150px;
        background-image: url("../assets/business/list4.png");
      }
      &:nth-child(5):after{
        left: 40%;
        top: -150px;
        background-image: url("../assets/business/list5.png");
      }
      &:nth-child(6):after{
        right: 150px;
        top: -120px;
        background-image: url("../assets/business/list6.png");
      }
      &:nth-child(7):after{
        left: 40%;
        top: -120px;
        background-image: url("../assets/business/list7.png");
      }
    }
    .text-stroke{
      -webkit-text-stroke: 1px black; //文字描边
      color: transparent;
      &:hover{
        color:black;
        -webkit-text-stroke: 1px white; //文字描边
      }
      &:hover:after{
        opacity: 1;
      }
    }
    .text-entity{
      &:hover{
        -webkit-text-stroke: 1px black; //文字描边
        color: white;
      }
      &:hover:after{
        opacity: 1;
      }
    }
  }
.media{
  height: 1350px;
  background: #3B3B3B;
  padding-top: 130px;
  box-sizing: border-box;
  .media-title{
    white-space: pre-line;
    padding-left: 110px;
    box-sizing: border-box;
    font-size: 80px;
    font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
    font-weight: 800;
    color: #FFFFFF;
  }
  .media-btn{
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 110px;
    img{
      cursor: pointer;
      width: 112px;
      height: 112px;
    }
  }
.media-img{
  width: 100%;
  /deep/.slider-item{
    display: block;
  }
  /deep/.slider-wrapper{
    align-items: baseline !important;
  }
  /deep/.slider-active{
    width: 822px !important;
    img{
      transition: all .5s;
      height: 671px !important;
    }

  }
  .slider-text{
  text-align: center;
    font-size: 30px;
    font-family: Source Han Sans SC-Medium, Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  img{
    width: 100%;
    height: 503px;
    pointer-events: none;
  }
}
}
  .release{
    position: relative;
    height: 659px;
    margin: 300px 0 0;
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 1153px;
      height: 659px;
    }
    p{
      top: 200px;
      right: 200px;
      position: absolute;
      z-index: 1;
      font-size: 80px;
      font-family: Source Han Sans SC-Heavy, Source Han Sans SC;
      font-weight: 800;
      color: #222222;
    }
  }
  .icon{
    padding: 324px 45px 68px;
    position: relative;
    .icon-go{
      &:hover{
        opacity: 1;
      }
      cursor: pointer;
      transition: all .8s;
      opacity: 0;
      position: absolute;
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);
      img{
        width: 248px;
        height: 248px;

      }
    }
    .icon-text{
      font-size: 80px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #222222;
    }
    .icon-img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 120px 0;
    }
    .icon-text2:hover +.icon-go{
      opacity: 1;
    }
    .icon-text3:hover +.icon-go{
      opacity: 1;
    }
    .icon-text2{
      font-size: 55px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #AAAAAA;
      text-align: center;
    }
    .icon-text3{
      text-align: center;
      font-size: 111px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #BBBBBB;
      margin-top: 16px;
      margin-bottom: 200px;
    }
  }
}
</style>
